import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import * as Utils from "../Utils/common";

export const fetchMainNavigation = createAsyncThunk(
    'navigation/fetchMainNavigation',
    async (_, thunkAPI) => {
        try {
            const response = await fetch('/config.json');
            const data = await response.json();

            // Get the service menu items from an API if the menu contains 'service'
            const updatedMenu = await Promise.all(
                data.mainNav.map(async (item) => {
                    if (item.slug === 'service') {
                        const serviceMenu = await fetchServiceMenu();
                        return { ...item, sub: serviceMenu };
                    }
                    return item;
                })
            );

            return updatedMenu;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

const fetchServiceMenu = async () => {
    try {
        return  await Utils.callAPI("get", `/service/menu`, {});
    } catch (error) {
        console.error('Error fetching service menu:', error);
        return [];
    }
};
const initialState = {
    navigations: [],
    status: 'idle',
}

const mainNavigationSlice = createSlice({
    name: "navigation",
    initialState,
    reducers: {},
    extraReducers: (builder => {
        builder.addCase(fetchMainNavigation.pending, (state) => {
            state.status = 'loading';
        })

        builder.addCase(fetchMainNavigation.fulfilled, (state, action) => {
            state.navigations = action.payload;
            state.status = 'succeeded';
        })

        builder.addCase(fetchMainNavigation.rejected, (state) => {
            state.status = 'failed';
        })
    })
});

export default mainNavigationSlice.reducer;
import { useEffect } from "react";
import anime from 'animejs/lib/anime.es.js';
import Splitting from "splitting";

const useDataAnimateAttribute = () => {
  useEffect(() => {
    let animElements = document.querySelectorAll("[data-animate]");
    const animElementsResults = Splitting({ target: animElements, by: "chars" });

    animElements.forEach(function (item) {
      const animationType = item.getAttribute("data-animate");

      switch (animationType) {
        case 'bounce' :
          item.addEventListener("mouseenter", function hoverAnimation() {
            anime({
              targets: item.querySelectorAll(".char"),
              opacity: [0, 1],
              duration: 50,
              easing: "easeOutCirc",
              delay: anime.stagger(50),

              translateY: [
                {
                  value: function () {
                    return anime.random(-15, 15);
                  },
                  duration: 200,
                },
                {
                  value: 0,
                  duration: 200,
                },
              ],
            });
          });
          break;

        case 'ease' :
          item.addEventListener("mouseenter", function hoverAnimation() {
            let letter = item.querySelectorAll(".char");
            anime.remove(letter);
            anime
                .timeline({
                  targets: letter,
                })
                .add({
                  translateX: [0, -30],
                  opacity: [1, 0],
                  easing: "easeInExpo",
                  duration: 500,
                  delay: (el, i) => 30 * i,
                })
                .add({
                  translateX: [40, 0],
                  opacity: [0, 1],
                  easing: "easeOutExpo",
                  duration: 1100,
                  delay: (el, i) => 30 * i,
                });
          });
          break;

        default:
          // console.warn(`Unknown animation: ${animationType}`);
      }

    });
  });
};

export default useDataAnimateAttribute;

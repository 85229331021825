import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 100); // Slight delay to ensure full load
        };

        // Wait until page is fully loaded
        if (document.readyState === "complete") {
            handleScroll();
        } else {
            window.addEventListener("load", handleScroll);
        }

        return () => window.removeEventListener("load", handleScroll);
    }, [pathname]);

    return null;
};

export default ScrollToTop;
